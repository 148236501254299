import React from 'react';

const MaintenancePage = () => {
    return (
        <div>
            <h1>Sorry, the site is currently under maintenance.</h1>
            <p>Please check back later.</p>
        </div>
    );
};

export default MaintenancePage;
